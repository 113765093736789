/******* screen-medium.less *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.desk {
  max-width: calc(100% - 48px);
}
#home {
  margin: 10px 4%;
}
#head {
  padding: 0 2.08333333%;
}
@media only screen and (max-width: 1024px) {
  .navbar {
    width: calc(100% - 48px);
    margin: 0 auto;
  }
}
#mobile-navi .meta.home {
  margin-left: 4.16666667%;
}
.vcard,
#servicesContact {
  padding: 0 4.34782609%;
}
#servicesContact {
  float: right;
}
#servicesContact .meta {
  float: left;
}
#servicesContact .meta.service_phone {
  position: relative;
  overflow: visible;
}
#servicesContact .meta.service_phone:before {
  content: "+1 936 321 2001";
  display: block;
  position: absolute;
  left: 0;
  transform: translateX(-100%);
  top: 0;
  padding-right: 15px;
  color: #cc071e;
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.8;
  font-weight: 700;
  opacity: 0;
  visibility: hidden;
  transition: all 250ms;
}
#servicesContact .meta:hover.service_phone:before,
#servicesContact .meta:focus.service_phone:before {
  opacity: 1;
  visibility: visible;
}
.services {
  padding: 0 4.16666667%;
}
#servicesLegal,
#services,
.copy {
  float: left;
}
#servicesLegal:after,
#services:after {
  content: " | ";
}
#services,
.copy {
  margin-left: 4px;
}
.container--left,
.container--right {
  width: 95.83333333%;
  margin-left: 2.08333333%;
}
@media only screen and (max-width: 1024px) {
  .section--footer .container--left,
  .section--footer .container--right {
    width: 47.91666667%;
    margin-bottom: 30px;
  }
  .section--footer .container--right {
    margin-left: 0;
    margin-right: 2.08333333%;
  }
}
@media only screen and (max-width: 1024px) {
  .area.main {
    width: 100%;
  }
  .area.main > .unit {
    margin-right: 0%;
    margin-left: 0%;
    width: 100%;
  }
  .area.main .foot {
    margin-right: 2.17391304%;
    margin-left: 2.17391304%;
    width: 95.65217391%;
  }
  .area.main .part {
    margin-right: 2.17391304%;
    margin-left: 2.17391304%;
    width: 95.65217391%;
  }
  .area.main .tiny {
    width: 28.98550725%;
  }
  .area.main > .slim {
    width: 33.33333333%;
  }
  .area.main > .slim .foot,
  .area.main > .slim .part {
    margin-right: 6.52173913%;
    margin-left: 6.52173913%;
  }
  .area.main > .slim .part {
    width: 86.95652174%;
  }
  .area.main > .slim .tiny {
    width: 36.95652174%;
  }
  .area.side {
    width: 100%;
  }
  .area.side > .unit {
    margin-right: 0%;
    margin-left: 0%;
    width: 100%;
  }
  .area.side .foot {
    margin-right: 1.75438596%;
    margin-left: 1.75438596%;
    width: 96.49122807%;
  }
  .area.side .part {
    margin-right: 1.75438596%;
    margin-left: 1.75438596%;
    width: 96.49122807%;
  }
  .area.side .tiny {
    width: 46.49122807%;
  }
  .area.side > .slim {
    width: 50%;
  }
  .area.side > .slim .foot,
  .area.side > .slim .part {
    margin-right: 3.50877193%;
    margin-left: 3.50877193%;
  }
  .area.side > .slim .part {
    width: 92.98245614%;
  }
  .area.side > .slim .tiny {
    width: 42.98245614%;
  }
  .area.main .unit.seam.wide {
    width: 66.66666667%;
  }
  .area.main .unit.seam.wide .part {
    margin-left: 3.40909091%;
    margin-right: 3.40909091%;
  }
  .area.main .unit.seam.wide .part.tall {
    width: 93.18181818%;
  }
  .area.main .unit.seam.wide .part.tiny {
    width: 43.18181818%;
  }
}
.area .unit.form.wide .cb-form-required {
  width: 64.54545455%;
  margin-left: 35.45454545%;
}
.area .unit.form.wide .tile .name {
  width: 31.06060606%;
  text-align: right;
}
.area .unit.form.wide .tile input.text,
.area .unit.form.wide .tile textarea {
  width: 64.54545455%;
}
.area .unit.form.wide input.submit,
.area .unit.form.wide button.cb-hybrid {
  width: 30%;
  margin-left: 35.45454545%;
}
.main > .wide.form .tile .name,
.main > .wide a.capt {
  float: left;
  width: 33%;
}
.main > .wide.form .tile .chop,
.main > .wide.form .tile textarea,
.main > .wide.form .tile input.text,
.main > .wide.form .tile .ctrl,
.main > .wide input.capt {
  float: right;
  width: 63%;
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 100px !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*# sourceMappingURL=./screen-medium.css.map */